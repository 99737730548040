<template>
  <div>
    <a-form-model
      ref="searchFrom"
      :model="search_form"
      layout="inline"
      class="flex-wrap"
    >
      <a-form-model-item label="规则名称">
        <a-input
          placeholder="请输入"
          v-model.trim="search_form.rule_name"
          :maxLength="100"
          style="width: 250px"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item class="order-madel">
        <div>
          <a-button
            class="ml-10p"
            :icon="'redo'"
            @click="resetSearch"
            type="dashed"
            >重置</a-button
          >
          <a-button
            class="ml-10p"
            :type="'primary'"
            :icon="'search'"
            @click="searchClick"
          >
            查询
          </a-button>
        </div>
      </a-form-model-item>
    </a-form-model>
    <div class="flex-wrap mtp">
      <a-radio-group
        button-style="solid"
        v-model="search_form.type"
        @change="changeRadio"
      >
        <a-radio-button value="list-mine"> 我的 </a-radio-button>
        <a-radio-button value="list-all"> 全部 </a-radio-button>
      </a-radio-group>
      <a-button :type="'primary'" :icon="'plus'" @click="goToCreate(0)">
        新建取数规则
      </a-button>
    </div>
    <nm-table
      :configPayload="configPayload"
      :selectValue="search_form"
      ref="nmtable"
    >
      <div slot="action_all" slot-scope="text">
        <a-button type="link" @click="goteDetail(text.id)">详情</a-button>
        <a-button
          type="link"
          :loading="copy_loading && active_id == text.id"
          @click="copyListMine(text.id)"
          >复制至我的</a-button
        >
      </div>
      <div slot="action" slot-scope="text">
        <a-button
          type="link"
          style="color: red"
          :loading="delete_loading && active_id == text.id"
          @click="deleteMine(text.id)"
          >删除</a-button
        >
        <a-button type="link" @click="exportRule(text.id)">导出</a-button>
        <a-button type="link" @click="goToCreate(text.id)">编辑</a-button>
      </div>
    </nm-table>
  </div>
</template>
<script>
import _http from "@/api/core/Analyse.js";
import NmTable from "@/components/nm-table.vue";
const configPayload = {
  columns: [
    {
      title: "ID",
      dataIndex: "id",
      align: "center",
    },
    {
      title: "规则名称",
      dataIndex: "rule_name",
      align: "center",
    },
    {
      title: "使用次数",
      dataIndex: "use_count",
      align: "center",
    },
    {
      title: "更新时间",
      dataIndex: "mtime",
      align: "center",
    },
    {
      title: "操作",
      scopedSlots: { customRender: "action" },
      align: "center",
    },
  ],
  http_api: _http.getRuleTable,
};
const configPayloadAll = {
  columns: [
    {
      title: "ID",
      dataIndex: "id",
      align: "center",
    },
    {
      title: "规则名称",
      dataIndex: "rule_name",
      align: "center",
    },
    {
      title: "使用次数",
      dataIndex: "use_count",
      align: "center",
    },
    {
      title: "创建人",
      dataIndex: "creator_name",
      align: "center",
    },
    {
      title: "更新时间",
      dataIndex: "mtime",
      align: "center",
    },
    {
      title: "操作",
      scopedSlots: { customRender: "action_all" },
      align: "center",
    },
  ],
  http_api: _http.getRuleTable,
};

export default {
  components: {
    NmTable,
  },
  data() {
    return {
      configPayload: configPayload,
      search_form: {
        rule_name: "",
        type: "list-mine",
      },
      copy_loading: false,
      active_id: null,
      delete_loading: false,
    };
  },
  mounted() {},
  methods: {
    changeRadio(value) {
      // console.log("vaueasfasf", value.target.value);
      if (value.target.value == "list-all") {
        this.configPayload = configPayloadAll;
      } else {
        this.configPayload = configPayload;
      }
      this.$refs.nmtable.getTableList(1, 10);
    },
    resetSearch() {
      this.search_form.rule_name = "";
      this.$refs.nmtable.getTableList(1, 10);
    },
    searchClick() {
      this.$refs.nmtable.getTableList(1, 10);
    },
    goToCreate(value) {
      this.$router.push({
        name: "dataAnalyse:create",
        query: {
          id: value,
        },
      });
    },
    goteDetail(value) {
      this.$router.push({
        name: "dataAnalyse:create",
        query: {
          id: value,
          detail:true
        },
      });
    },
    // 导出
    exportRule(value) {
      let that = this;
      _http.exportRule(value).then((res) => {
        // console.log("res", res);
        if (res.code == 200) {
          this.$confirm({
            title: "数据正在导出",
            content: (h) => (
              <div style="color:red;">可点击跳转前往新建规则页查看</div>
            ),
            onOk() {
              // console.log("OK");
              that.goToCreate(value);
            },
            onCancel() {
              // console.log("Cancel");
            },
            class: "test",
          });
        }
      });
    },
    // 复制至我的
    copyListMine(id) {
      this.copy_loading = true;
      this.active_id = id;
      _http
        .copyRule({ id })
        .then((res) => {
          // console.log(res);
          if (res.code == 200) {
            this.$message.success("成功复制至我的！");
            this.$refs.nmtable.getTableList(1, 10);
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          this.copy_loading = false;
        });
    },
    // 删除我的
    deleteMine(id) {
      this.delete_loading = true;
      this.active_id = id;
      _http
        .deleteMine(id)
        .then((res) => {
          // console.log(res);
          if (res.code == 200) {
            this.$message.success("删除成功！");
            this.$refs.nmtable.getTableList(1, 10);
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          this.delete_loading = false;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.flex-wrap {
  display: flex;
  justify-content: space-between;
}
.ml-10p {
  margin-left: 10px;
}
.mtp {
  margin: 20px 16px 20px 0;
}
</style>
